import PropType from "prop-types";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import pages from "../../../../utils/pages";
import Counter from "../../Counter/Counter";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";
import styles from "./Types.module.scss";

function ProductPickup({ list, onDelete, onQuantityChange, readOnly }) {
  const translate = useTranslate();

  const handleQuantityChange = async (newQuantity, item) => {
    if (newQuantity !== item.product.quantity) {
      const data = { product: { ...item.product, quantity: newQuantity } };
      onQuantityChange(item.id, data);
    }
  };
  return (
    <ContentType title={translate("CART_PRODUCT_PICKUP_TITLE")}>
      {list.map(item => (
        <Item
          key={item.id}
          orderData={item}
          onDelete={onDelete}
          readOnly={readOnly}
          addressTitle={translate("PICKUP_IN_BASE_STORAGE")}
        >
          <ItemInfo>
            <div>
              <Link
                to={{
                  pathname: `${pages.PRODUCTS.PATH}/${item.product.productId}`,
                  state: item.product,
                }}
              >
                <RowTitle text={item.product.description} />
              </Link>
              {item.product?.inventoryNumber && (
                <Row title={translate("INVENTORY_NUMBER_SHORT")} text={item.product.inventoryNumber} />
              )}
              <Row title={translate("PRODUCT_NUMBER_SHORT")} text={item.product.productNumber} />
            </div>
            {item.product?.inventoryNumber || readOnly ? (
              <p className={styles.quantityText}>
                {item.product.quantity} {item.product.unit}
              </p>
            ) : (
              <Counter
                onChange={quantity => handleQuantityChange(quantity, item)}
                min={1}
                max={item.product.maxQuantity}
                disabled={item.product?.inventoryNumber}
                startValue={item.product.quantity}
              />
            )}
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

ProductPickup.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      address: PropType.object,
      contentType: PropType.string,
      product: PropType.shape({
        id: PropType.string,
        description: PropType.string,
        productNumber: PropType.string,
        inventoryNumber: PropType.string,
        quantity: PropType.number,
        supplier: PropType.string,
      }),
      make: PropType.array,
      priority: PropType.object,
      status: PropType.number,
      subContentType: PropType.object,
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  readOnly: PropType.bool,
};

export default ProductPickup;
