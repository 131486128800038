import PropType from "prop-types";
import { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import Problem from "../../../assets/icons/Problem";
import { Button, THEME } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import { useTablet, useMobile } from "../../../utils/viewport";
import clientService from "../../../services/client/clientService";
import productService from "../../../services/product/productService";
import pages from "../../../utils/pages";
import Label from "../../shared/Label/Label";
import ProductImage, { ImagePreset } from "../../Products/ProductImage/ProductImage";
import ProductMake from "../../BaseStorages/InventoryDetail/Components/ProductMake";
import styles from "./HoldingDetail.module.scss";
import AccordionItem from "../../shared/AccordionItem/AccordionItem";

function HoldingDetail({ setBreadcrumbs, referrer, fromReceiverMenu }) {
  const translate = useTranslate();
  const query = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isInventory = product?.inventoryNumber;

  useEffect(() => {
    if (fromReceiverMenu) {
      setBreadcrumbs([
        {
          text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_HOLDINGS.NAME)}`,
          link: generatePath(pages.CLIENT_HOLDINGS.PATH, { id: query.id }),
        },
        { text: translate("HOLDING") },
      ]);
    } else {
      setBreadcrumbs([
        { text: translate("SEARCH_CLIENT"), link: referrer || pages.CLIENTS.PATH },
        {
          text: `${translate(pages.CLIENTS.NAME)} - ${translate(pages.CLIENT_HOLDINGS.NAME)}`,
          link: generatePath(pages.CLIENT_HOLDINGS.PATH, { id: query.id }),
        },
        { text: translate("HOLDING") },
      ]);
    }
  }, [setBreadcrumbs, translate, query.id, product?.description, referrer, fromReceiverMenu]);

  useEffect(() => {
    clientService.getHolding({ clientId: query.id, inventoryId: encodeURIComponent(query.holding) }).then(response => {
      setProduct(response.data);
    });
  }, [query]);

  const getLabel = async () => {
    setIsLoading(true);
    const response = await productService.getFixedAssetLabel(product?.inventoryNumber);
    if (response.isOk) {
      setIsLoading(false);
      //To open in a new window if response is ok
      const newWindow = window.open(response.data.url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  if (!product) {
    return <></>;
  }
  return (
    <div>
      {isTablet ? (
        <div>
          <h3 className={styles.contentTitle}>
            <span className={styles.title}>
              {product.description} <span>{product.hasDeviation && <Problem customCssClass={styles.deviation} />}</span>
            </span>
            <div className={styles.quantityInfo}>
              <span>
                {`${product?.quantity} ${product?.unit} `}
                {product?.allocatedQuantity >= 1 && (
                  <span className={styles.reserved}>
                    {`(${translate("OF_WHICH")} ${product?.allocatedQuantity} ${
                      product?.allocatedQuantity > 1 ? translate("HOLDING_RESERVED") : translate("ORDERS_RESERVED")
                    })`}
                  </span>
                )}
              </span>
            </div>
          </h3>
          <section className={styles.infoSection}>
            <div className={styles.productImage}>
              <ProductImage images={product?.images} preset={isMobile ? ImagePreset.MEDIUM : ImagePreset.SMALL} />
              {isInventory && (
                <div className={styles.printLabel}>
                  <Button onClick={() => getLabel()} theme={THEME.SECONDARY} isLoading={isLoading}>
                    {translate("PRINT_LABEL")}
                  </Button>
                </div>
              )}
            </div>

            <div className={styles.productInformation}>
              <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={product?.productNumber} />
              <Label inline label={translate("ARTICLE_CLASS")} value={product?.itemClass?.label} />
              <Label
                inline
                label={translate("ISO_CODE")}
                value={`${product?.isoCode?.value} - ${product?.isoCode?.label}`}
              />
              {isInventory && <Label inline label={translate("PRODUCT_OWNER")} value={product?.goodsOwner?.label} />}
              <Label inline label={translate("SUPPLIER")} value={product?.supplier?.label} />
              {isInventory && (
                <Label inline label={translate("INVENTORY_NUMBER_SHORT")} value={product?.inventoryNumber} hideEmpty />
              )}
              <Label inline label={translate("BATCH_NO")} value={product?.batch} />
              {isInventory && <Label inline label={translate("SERIAL_NUMBER_SHORT")} value={product?.serialNumber} />}
              {isInventory && <Label inline label={translate("SALES_PRICE")} value={product?.salesPrice} />}
              <Label inline label={translate("ACQUISITION_DATE")} value={product?.acquisitionDate} />
              <Label inline label={translate("LAST_INSPECTION_DATE")} value={product?.inspectionDate} />
              <Label inline label={translate("WARRANTY_TO_DATE")} value={product?.warrantyToDate} />
              {product?.prescriberName && (
                <Label
                  inline
                  label={translate("PRESCRIBED")}
                  value={`${product?.prescriptionDate} / ${product?.prescriberName}`}
                />
              )}

              {product?.handedOverBy && (
                <Label
                  inline
                  label={translate("DELIVERED")}
                  value={`${product?.handedOverDate} / ${product?.handedOverBy}`}
                />
              )}
              <div className={styles.description}>{product?.extendedDescription}</div>
            </div>
          </section>

          {isInventory && product.makes && (
            <AccordionItem title={translate("CUSTOMIZATION")}>
              <ProductMake makes={product.makes} />
            </AccordionItem>
          )}

          {!!product?.accessories?.length && (
            <AccordionItem title={translate("ACCESSORIES")}>
              <ul className={styles.accessoryList}>
                {product?.accessories?.map(accessory => (
                  <li key={accessory.id}>
                    <div className={styles.icon}>
                      <ProductImage images={accessory?.images} preset={ImagePreset.TINY} />
                    </div>
                    <div className={styles.grow}>
                      <div className={styles.titleRow}>
                        <h4>{accessory.description}</h4>
                        <span className={styles.accessoryQuantity}>{`${accessory.quantity} ${accessory.unit}`}</span>
                      </div>
                      <div className={styles.row}>
                        <Label
                          inline
                          label={translate("PRODUCT_NUMBER_SHORT")}
                          value={accessory?.productNumber}
                          hideEmpty
                        />
                        <Label
                          inline
                          label={translate("INVENTORY_NUMBER_SHORT")}
                          value={accessory?.inventoryNumber}
                          customCssClass={styles.accessoryInfo}
                          hideEmpty
                        />
                        <Label
                          inline
                          label={translate("ARTICLE_CLASS")}
                          value={accessory?.itemClass?.label}
                          hideEmpty
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </AccordionItem>
          )}
        </div>
      ) : (
        <section className={styles.outerSection}>
          <div>
            <h3 className={styles.contentTitle}>
              <div>
                {product.description} {product.hasDeviation && <Problem customCssClass={styles.deviation} />}
              </div>
              <div className={styles.quantityInfo}>
                <span>
                  {`${product?.quantity} ${product?.unit} `}
                  {product?.allocatedQuantity >= 1 && (
                    <span className={styles.reserved}>
                      {`(${translate("OF_WHICH")} ${product?.allocatedQuantity} ${
                        product?.allocatedQuantity > 1 ? translate("HOLDING_RESERVED") : translate("ORDERS_RESERVED")
                      })`}
                    </span>
                  )}
                </span>
              </div>
            </h3>

            {product.handedOverInformation && (
              <p className={styles.highlightedYellow}> {product.handedOverInformation} </p>
            )}

            <section className={styles.infoSection}>
              <div className={styles.information}>
                <div className={styles.row}>
                  <div className={styles.leftSection}>
                    <div>
                      <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={product?.productNumber} />
                    </div>
                    <div>
                      <Label inline label={translate("ARTICLE_CLASS")} value={product?.itemClass?.label} />
                    </div>
                    <div>
                      <Label
                        inline
                        label={translate("ISO_CODE")}
                        value={`${product?.isoCode?.value} - ${product?.isoCode?.label}`}
                      />
                      {isInventory && (
                        <Label inline label={translate("PRODUCT_OWNER")} value={product?.goodsOwner?.label} />
                      )}
                      <Label inline label={translate("SUPPLIER")} value={product?.supplier?.label} />
                    </div>
                  </div>
                  <div className={styles.rightSection}>
                    {isInventory && (
                      <Label
                        inline
                        label={translate("INVENTORY_NUMBER_SHORT")}
                        value={product?.inventoryNumber}
                        hideEmpty
                      />
                    )}
                    <Label inline label={translate("BATCH_NO")} value={product?.batch} />
                    {isInventory && (
                      <Label inline label={translate("SERIAL_NUMBER_SHORT")} value={product?.serialNumber} />
                    )}
                    {isInventory && <Label inline label={translate("SALES_PRICE")} value={product?.salesPrice} />}
                  </div>
                </div>
                <div>
                  {isInventory && (
                    <div className={styles.newSection}>
                      <Label inline label={translate("ACQUISITION_DATE")} value={product?.acquisitionDate} />
                      <Label inline label={translate("LAST_INSPECTION_DATE")} value={product?.inspectionDate} />
                      <Label inline label={translate("WARRANTY_TO_DATE")} value={product?.warrantyToDate} />
                    </div>
                  )}
                  <div className={styles.newSection}>
                    {product?.prescriberName && (
                      <Label
                        inline
                        label={translate("PRESCRIBED")}
                        value={`${product?.prescriptionDate} / ${product?.prescriberName}`}
                      />
                    )}

                    {product?.handedOverBy && (
                      <Label
                        inline
                        label={translate("DELIVERED")}
                        value={`${product?.handedOverDate} / ${product?.handedOverBy}`}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.description}>{product?.extendedDescription}</div>
              </div>
            </section>
            {isInventory && <ProductMake makes={product.makes} />}

            {!!product?.accessories?.length && (
              <section>
                <h3 className={styles.contentTitle}>
                  <div>{translate("ACCESSORIES")}</div>
                </h3>

                <ul className={styles.accessoryList}>
                  {product?.accessories?.map(accessory => (
                    <li key={accessory.id}>
                      <div className={styles.icon}>
                        <ProductImage images={accessory?.images} preset={ImagePreset.TINY} />
                      </div>
                      <div className={styles.grow}>
                        <div className={styles.row}>
                          <h4>{accessory.description}</h4>
                          <span className={styles.accessoryQuantity}>{`${accessory.quantity} ${accessory.unit}`}</span>
                        </div>
                        <div className={styles.row}>
                          <Label
                            inline
                            label={translate("PRODUCT_NUMBER_SHORT")}
                            value={accessory?.productNumber}
                            hideEmpty
                          />
                          <Label
                            inline
                            label={translate("INVENTORY_NUMBER_SHORT")}
                            value={accessory?.inventoryNumber}
                            customCssClass={styles.accessoryInfo}
                            hideEmpty
                          />
                          <Label
                            inline
                            label={translate("ARTICLE_CLASS")}
                            value={accessory?.itemClass?.label}
                            hideEmpty
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </div>
          <div className={styles.productImage}>
            <ProductImage images={product?.images} preset={ImagePreset.MEDIUM} />
            {isInventory && (
              <div className={styles.printLabel}>
                <Button onClick={() => getLabel()} theme={THEME.SECONDARY} isLoading={isLoading}>
                  {translate("PRINT_LABEL")}
                </Button>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

HoldingDetail.propTypes = {
  receiver: PropType.object,
  productList: PropType.array,
  setBreadcrumbs: PropType.func,
  referrer: PropType.string,
  fromReceiverMenu: PropType.bool,
};

export default HoldingDetail;
