import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";

function Fitting({ list, onDelete, readOnly }) {
  const translate = useTranslate();

  //TODO Add info about schedule booking and adjust fields/info
  return (
    <ContentType title={translate("CART_FITTING_TITLE")}>
      {list.map(item => (
        <Item
          key={item.id}
          orderData={item}
          onDelete={onDelete}
          readOnly={readOnly}
          addressTitle={translate("WORK_ORDER_ADDRESS_TITLE")}
        >
          <ItemInfo grid={false}>
            <Row title={translate("FITTING_TYPE")} text={item.fittingType.name} margin />
            {!item.isNewItem && <RowTitle text={item.product?.description} />}
            <Row title={translate("INVENTORY_NUMBER_SHORT")} text={item.product?.inventoryNumber} />
            <Row title={translate("PRODUCT_NUMBER_SHORT")} text={item.product?.productNumber} margin />
            <Row title={translate("DESCRIBE_AID")} text={item.isNewItem ? item.product?.description : item.text} />
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

Fitting.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      contentType: PropType.string,
      product: PropType.shape({
        description: PropType.string,
        inventoryNumber: PropType.string,
        productNumber: PropType.string,
      }),
      subContentType: PropType.shape({
        value: PropType.string,
      }),
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  readOnly: PropType.bool,
};

export default Fitting;
